import { Target } from "lucide-react";
import { cn } from "@/lib/utils";

interface LogoProps {
  className?: string;
}

export function Logo({ className }: LogoProps) {
  return (
    <div className={cn("flex items-center gap-2", className)}>
      <Target className="h-8 w-8 text-primary" />
      <span className="text-2xl font-bold tracking-tight">
        RIVAL<span className="text-primary">TRACK</span>
      </span>
    </div>
  );
}