export const IMAGES = {
  banner: "https://consultdex.com/rivaltrack-assets/brian-headshot-rt.png",
  exampleReport: "https://consultdex.com/rivaltrack-assets/example-report-rival-track.png",
  brianOnsite: "https://consultdex.com/rivaltrack-assets/brian-onsite.png",
} as const;

export const ROUTES = {
  exampleReport: "#example-report-section",
  pricing: "#pricing-section",
  live: "#live-section",
  consultants: "#consultant-section",
  signUp: "#pricing-section",
} as const;

export const STRIPE_LINKS = {
  weekly: "https://buy.stripe.com/4gw4hH8xCbzx8qkcMN",
  monthly: "https://buy.stripe.com/9AQ29zdRWcDBgWQdQS",
} as const;

export const META = {
  title: "RivalTrack - Competitor Monitoring as a Service",
  description: "Professional competitor monitoring service with expert analysis and insights delivered monthly.",
  keywords: "competitor monitoring, market analysis, competitive intelligence, business intelligence",
} as const;

export const CONTACT = {
  support: "support@rivaltrack.com",
} as const;

export type Category = {
  id: string;
  path: string[];
  name: string;
};

export const FALLBACK_CATEGORIES: Category[] = [
  { 
    id: "13329",
    path: ["Business & Industrial", "Building Construction & Maintenance", "Plumbing"],
    name: "Plumbing Fixtures"
  },
  {
    id: "13330",
    path: ["Technology", "Software", "Business Software"],
    name: "CRM Systems"
  },
  {
    id: "13331",
    path: ["Retail", "E-commerce", "Online Marketplaces"],
    name: "Digital Storefronts"
  },
] as const;