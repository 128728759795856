import { motion } from 'framer-motion';
import { ArrowRight, Award, Users2, LineChart } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export function ConsultantTeaser() {
  return (
    <section id="consultant-section" className="py-24 bg-muted/50">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              Expert Consultants with Proven Track Records
            </h2>
            <p className="text-lg text-muted-foreground">
              Our lead consultant brings over 15 years of experience in digital marketing and business development, with a proven track record of success.
            </p>
            <div className="grid grid-cols-3 gap-6">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Award className="h-5 w-5 text-primary" />
                  <span className="font-semibold">5+</span>
                </div>
                <p className="text-sm text-muted-foreground">Successful Exits</p>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Users2 className="h-5 w-5 text-primary" />
                  <span className="font-semibold">50+</span>
                </div>
                <p className="text-sm text-muted-foreground">Teams Managed</p>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <LineChart className="h-5 w-5 text-primary" />
                  <span className="font-semibold">10+</span>
                </div>
                <p className="text-sm text-muted-foreground">Years Listed</p>
              </div>
            </div>
            <Button asChild>
              <Link 
                to="/about-our-consultants"
                onClick={() => window.scrollTo(0, 0)}
                className="flex items-center gap-2"
              >
                Meet Our Lead Consultant
                <ArrowRight className="h-4 w-4" />
              </Link>
            </Button>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="relative"
          >
            <div className="absolute inset-0 bg-gradient-to-tr from-primary/10 to-primary/5 rounded-lg -z-10 blur-2xl" />
            <img
              src="https://consultdex.com/rivaltrack-assets/brian-onsite.png"
              alt="Our Lead Consultant"
              className="rounded-lg shadow-xl"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
}