import { Logo } from "@/components/ui/logo";
import { Newsletter } from "@/components/sections/Newsletter";
import { FooterSocials } from "./FooterSocials";
import { FooterLegal } from "./FooterLegal";
import { Separator } from "@/components/ui/separator";

export function Footer() {
  return (
    <footer className="bg-background border-t">
      <div className="mx-auto max-w-[1400px] px-4 sm:px-6 lg:px-8">
        <div className="py-12">
          {/* Main Footer Content */}
          <div className="flex flex-col items-center max-w-md mx-auto text-center">
            {/* Logo */}
            <div className="mb-8">
              <Logo />
            </div>

            {/* Newsletter */}
            <div className="w-full mb-12">
              <Newsletter />
            </div>

            {/* Social Icons */}
            <FooterSocials />

            {/* Separator */}
            <Separator className="my-8 w-full" />

            {/* Legal Section */}
            <FooterLegal />
          </div>
        </div>
      </div>
    </footer>
  );
}