import { 
  Award, 
  Building2, 
  Code2, 
  FileText, 
  GraduationCap, 
  Users2
} from 'lucide-react';
import { TimelineEvent } from './TimelineEvent';

export function Timeline() {
  return (
    <div className="relative py-24 space-y-24">
      <TimelineEvent
        year="2008-2021"
        title="Multiple Business Exits"
        description="Successfully created exits on 5 businesses, with one selling for over 6x EBIDTA."
        icon={<Award className="w-5 h-5" />}
        align="left"
      />

      <TimelineEvent
        year="2012-2022"
        title="Moz Recommended Agency"
        description="My agencies were featured on the Moz recommended list for over a decade, establishing our reputation in the SEO industry."
        icon={<Award className="w-5 h-5" />}
        align="right"
      />

      <TimelineEvent
        year="2010-Present"
        title="Media Recognition"
        description="Content created received recognition from major media outlets, including the New York Times."
        icon={<FileText className="w-5 h-5" />}
        align="left"
      />

      <TimelineEvent
        year="2010-Present"
        title="Outsourcing Excellence"
        description="Managed over $1.7 million in outsourcing through Upwork and supervised 50+ contractors, demonstrating strong team management capabilities."
        icon={<Users2 className="w-5 h-5" />}
        align="right"
      />

      <TimelineEvent
        year="2015-2021"
        title="Mentorship Success"
        description="Witnessed the success of former interns who went on to create and exit their own service businesses."
        icon={<GraduationCap className="w-5 h-5" />}
        align="left"
      />

      <TimelineEvent
        year="2009"
        title="WordPress Innovation"
        description="Designed a WordPress plugin that achieved over 30,000 downloads, contributing to the WordPress community."
        icon={<Code2 className="w-5 h-5" />}
        align="right"
      />

      <TimelineEvent
        year="2005"
        title="Digital Marketing Journey Begins"
        description="Started the journey in digital marketing managing 300,000/year in backlinks and growing GoTickets into the #3 Ticket reseller in the country, laying the foundation for future successes in SEO and business development."
        icon={<Building2 className="w-5 h-5" />}
        align="left"
      />
    </div>
  );
}