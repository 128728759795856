import { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { Sparkles } from "lucide-react";
import { subscribeToNewsletter } from '@/lib/api';
import { ApiError } from '@/lib/api/utils';

export function Newsletter() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await subscribeToNewsletter({ email });
      
      if (response.success) {
        toast({
          title: "Welcome aboard! 🎉",
          description: "You're now subscribed to the Snack Pack Recaps!",
        });
        setEmail('');
      }
    } catch (error) {
      const errorMessage = error instanceof ApiError 
        ? error.message 
        : 'Something went wrong. Please try again later.';
        
      toast({
        title: "Subscription failed",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-center space-y-6">
      <div className="flex justify-center items-center gap-2">
        <Sparkles className="h-5 w-5 text-primary" />
        <h3 className="text-2xl font-bold">Snack Pack Recaps</h3>
        <Sparkles className="h-5 w-5 text-primary" />
      </div>
      
      <p className="text-muted-foreground">
        Get bite-sized insights from our best Rival Track shows delivered straight to your inbox.
      </p>
      
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <Input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full"
          disabled={isLoading}
          aria-label="Email address"
        />
        <Button 
          type="submit" 
          disabled={isLoading}
          className="w-full"
          aria-label={isLoading ? "Subscribing..." : "Subscribe to newsletter"}
        >
          {isLoading ? "Subscribing..." : "Subscribe"}
        </Button>
      </form>
    </div>
  );
}