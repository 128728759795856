import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ExternalLink } from "lucide-react";
import { IMAGES } from "@/lib/constants";

export function ExampleReport() {
  return (
    <section id="example-report-section" className="py-24 bg-background">
      <div className="container">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
            Example Report
          </h2>
          <p className="mt-6 text-lg leading-8 text-muted-foreground">
            See how we transform complex competitor data into clear, actionable insights
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-5xl">
          <Card className="overflow-hidden">
            <div className="aspect-video relative group">
              <img
                src={IMAGES.exampleReport}
                alt="Example report preview"
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                <Button asChild variant="secondary" size="lg">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1s1c74aZQRwVkOaJrYUyuO44oZFGxqKifRd-rqtV9LYI/edit#gid=0"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2"
                  >
                    View Full Report <ExternalLink className="h-4 w-4" />
                  </a>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
}