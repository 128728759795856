import { Button } from "@/components/ui/button";
import { ArrowRight, LineChart } from "lucide-react";
import { IMAGES, ROUTES } from "@/lib/constants";

export function Hero() {
  return (
    <div className="relative isolate overflow-hidden bg-background">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:flex lg:px-8 lg:py-24">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
          <div className="mt-8 sm:mt-12 lg:mt-0">
            <a href={ROUTES.pricing} className="inline-flex space-x-6">
              <span className="rounded-full bg-primary/10 px-3 py-1 text-sm font-semibold leading-6 text-primary ring-1 ring-inset ring-primary/10">
                <LineChart className="h-4 w-4 inline-block mr-2" />
                Competitor Intel
              </span>
              <span className="inline-flex items-center space-x-2 text-sm font-medium text-muted-foreground">
                <span>Expert Analysis</span>
                <ArrowRight className="h-4 w-4" />
              </span>
            </a>
          </div>
          <h1 className="mt-8 text-4xl font-bold tracking-tight sm:text-6xl">
            Competitor Monitoring as a Service
          </h1>
          <p className="mt-6 text-lg leading-8 text-muted-foreground">
            We track your rivals so you don't have to. Get monthly competitor snapshots from expert consultants delivered straight to your inbox.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <Button size="lg" asChild>
              <a href={ROUTES.pricing}>Get started</a>
            </Button>
            <Button variant="outline" size="lg" asChild>
              <a href={ROUTES.exampleReport} className="flex items-center gap-2">
                View example report
                <ArrowRight className="h-4 w-4" />
              </a>
            </Button>
          </div>
        </div>
        <div className="mx-auto mt-12 sm:mt-16 lg:mt-0 lg:ml-auto lg:mr-0 lg:w-[45%] lg:flex-none">
          <div className="relative w-full">
            <div className="absolute -inset-y-4 -inset-x-8 lg:-inset-y-6 lg:-inset-x-12">
              <div className="absolute inset-0 bg-gradient-to-tr from-primary/10 to-primary/5 opacity-50 blur-3xl" />
            </div>
            <img
              src={IMAGES.banner}
              alt="RivalTrack Dashboard"
              width={2432}
              height={1442}
              className="relative w-full rounded-xl shadow-2xl ring-1 ring-white/10 transition-transform duration-300 hover:scale-[1.02]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}