import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';

interface TimelineEventProps {
  year: string;
  title: string;
  description: string;
  icon: ReactNode;
  align?: 'left' | 'right';
  className?: string;
}

export function TimelineEvent({ 
  year, 
  title, 
  description, 
  icon,
  align = 'left',
  className 
}: TimelineEventProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className={cn(
        'grid grid-cols-1 md:grid-cols-5 gap-4 md:gap-6 relative',
        className
      )}
    >
      {/* Timeline line */}
      <div className="hidden md:block absolute left-1/2 top-0 bottom-0 w-px bg-border -z-10" />

      {/* Content */}
      <div className={cn(
        'col-span-2 space-y-3',
        align === 'right' ? 'md:col-start-4' : ''
      )}>
        <div className="flex items-center gap-3">
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center text-primary">
            {icon}
          </div>
          <span className="text-lg font-semibold">{year}</span>
        </div>
        <h3 className="text-xl font-bold">{title}</h3>
        <p className="text-muted-foreground">{description}</p>
      </div>
    </motion.div>
  );
}