import { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { triggerConfetti } from '@/lib/confetti';
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Youtube } from 'lucide-react';
import { fetchCategories } from '@/lib/api';
import type { Category } from '@/lib/constants';
import { motion, AnimatePresence } from 'framer-motion';

export function LiveDemo() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    async function loadCategories() {
      try {
        setIsLoading(true);
        const data = await fetchCategories();
        if (mounted) {
          setCategories(data);
        }
      } catch (err) {
        console.error('Failed to load categories:', err);
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    }

    loadCategories();

    return () => {
      mounted = false;
    };
  }, []);

  const handleLiveDemo = () => {
    const randomIndex = Math.floor(Math.random() * categories.length);
    const category = categories[randomIndex];
    
    setSelectedCategory(category);
    triggerConfetti();
  };

  return (
    <section id="live-section" className="w-full py-24 bg-gradient-to-b from-background to-muted">
      <div className="mx-auto max-w-[1400px] px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="inline-flex items-center gap-4 mb-6">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                Live Demo
              </h2>
              <Youtube className="h-8 w-8 text-primary animate-pulse" />
            </div>
            <p className="mt-4 text-lg leading-8 text-muted-foreground">
              Click below to see a random category we monitor
            </p>
          </motion.div>

          <motion.div 
            className="mt-10"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button
              size="lg"
              onClick={handleLiveDemo}
              disabled={isLoading}
              className="relative overflow-hidden bg-primary hover:bg-primary/90 text-primary-foreground"
            >
              <span className="relative z-10">
                {isLoading ? 'Loading Categories...' : 'Show Random Category'}
              </span>
              <div className="absolute inset-0 bg-gradient-to-r from-primary/0 via-primary-foreground/10 to-primary/0 animate-shimmer" />
            </Button>
          </motion.div>

          <AnimatePresence mode="wait">
            {selectedCategory && (
              <motion.div
                key={selectedCategory.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="mt-8"
              >
                <Card className="overflow-hidden bg-card/50 backdrop-blur-sm border-primary/20">
                  <CardContent className="pt-6">
                    <motion.div
                      initial={{ scale: 0.8 }}
                      animate={{ scale: 1 }}
                      transition={{ type: "spring", stiffness: 200, damping: 15 }}
                    >
                      <Badge variant="outline" className="mb-4 text-lg px-4 py-1">
                        {selectedCategory.id}
                      </Badge>
                    </motion.div>
                    <div className="space-y-4">
                      <p className="text-sm text-muted-foreground">
                        {selectedCategory.path.join(' > ')}
                      </p>
                      <p className="text-3xl font-bold bg-gradient-to-r from-primary to-primary/50 bg-clip-text text-transparent">
                        {selectedCategory.name}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
}