import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { Hero } from "@/components/sections/Hero";
import { Features } from "@/components/sections/Features";
import { ExampleReport } from "@/components/sections/ExampleReport";
import { Pricing } from "@/components/sections/Pricing";
import { LiveDemo } from "@/components/sections/LiveDemo";
import { ConsultantTeaser } from "@/components/sections/ConsultantTeaser";
import About from "@/pages/About";

function AppContent() {
  const location = useLocation();
  const isAboutPage = location.pathname === '/about-our-consultants';

  return (
    <div className="min-h-screen bg-background font-sans antialiased">
      {!isAboutPage && <Header />}
      <Routes>
        <Route path="/about-our-consultants" element={<About />} />
        <Route path="/" element={
          <main>
            <div className="mx-auto max-w-[1400px] px-4 sm:px-6 lg:px-8">
              <Hero />
            </div>
            <Features />
            <div className="mx-auto max-w-[1400px] px-4 sm:px-6 lg:px-8">
              <ExampleReport />
              <Pricing />
            </div>
            <ConsultantTeaser />
            <LiveDemo />
          </main>
        } />
      </Routes>
      {!isAboutPage && <Footer />}
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}