import { 
  Clock,
  Search,
  Brain,
  Lightbulb
} from "lucide-react";
import { cn } from "@/lib/utils";

const features = [
  {
    name: "24/7 Monitoring",
    description: "Monthly competitor snapshots sent to your inbox or team slack.",
    icon: Clock,
    gradient: "from-blue-500 to-cyan-500",
    animation: "animate-pulse"
  },
  {
    name: "Comprehensive Coverage",
    description: "We track Search, Social and Traditional media outlets.",
    icon: Search,
    gradient: "from-purple-500 to-pink-500",
    animation: "animate-float"
  },
  {
    name: "Expert Analysis",
    description: "Reports written by consultants with over 20 years of experience.",
    icon: Brain,
    gradient: "from-green-500 to-emerald-500",
    animation: "animate-float"
  },
  {
    name: "Actionable Insights",
    description: "Clear insights about your competitors' marketing and operations.",
    icon: Lightbulb,
    gradient: "from-orange-500 to-red-500",
    animation: "animate-glow"
  },
];

export function Features() {
  return (
    <section className="w-full py-24 bg-muted/50">
      <div className="mx-auto max-w-[1400px] px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
            We Monitor While You Sleep
          </h2>
          <p className="mt-6 text-lg leading-8 text-muted-foreground">
            Get comprehensive competitor insights delivered directly to your team, analyzed by industry experts.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
            {features.map((feature) => (
              <div key={feature.name} className="relative group">
                <div className={cn(
                  "absolute -inset-1 rounded-lg bg-gradient-to-r",
                  feature.gradient,
                  "opacity-25 blur-xl transition-all group-hover:opacity-75 group-hover:blur-2xl"
                )} />
                <div className="relative flex flex-col bg-card rounded-lg p-6 ring-1 ring-muted transition-all hover:ring-primary">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7">
                    <div className={cn(
                      "rounded-lg p-2 bg-gradient-to-br",
                      feature.gradient,
                      feature.animation
                    )}>
                      <feature.icon className="h-5 w-5 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-muted-foreground">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
}