import { ENDPOINTS } from './endpoints';
import { validateEmail } from './validation';
import { NewsletterSignup, NewsletterResponse } from './types';
import { ApiError } from './utils';

export async function subscribeToNewsletter({ email }: NewsletterSignup): Promise<NewsletterResponse> {
  try {
    // Validate email
    validateEmail(email);

    await fetch(ENDPOINTS.newsletter.subscribe, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    return {
      success: true,
      message: 'Successfully subscribed to newsletter',
      data: { email }
    };
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    
    throw new ApiError(
      'Unable to subscribe at this time. Please try again later.'
    );
  }
}