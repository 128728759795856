import { motion } from 'framer-motion';
import { Timeline } from '@/components/sections/Timeline';
import { Testimonials } from '@/components/sections/Testimonials';
import { IMAGES } from '@/lib/constants';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/button';

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="min-h-screen bg-background">
      {/* Back to Home Button */}
      <div className="fixed top-6 left-6 z-50">
        <Button variant="outline" size="sm" asChild>
          <Link to="/" className="flex items-center gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Link>
        </Button>
      </div>

      {/* Hero Section */}
      <section className="relative h-screen overflow-hidden">
        <div className="absolute inset-0">
          <motion.div
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.8 }}
            className="w-full h-full"
          >
            <img
              src="https://images.unsplash.com/photo-1497366216548-37526070297c?auto=format&fit=crop&q=80"
              alt="Office workspace"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black/50" />
          </motion.div>
        </div>
        
        <div className="relative h-full flex items-center">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="max-w-4xl"
            >
              <h1 className="text-4xl md:text-7xl font-bold text-white mb-6">
                Meet Our Lead Consultant
              </h1>
              <p className="text-xl md:text-2xl text-white/90">
                With over 15 years of experience in digital marketing and business development
              </p>
            </motion.div>
          </div>
        </div>

        {/* Scroll Indicator */}
        <motion.div 
          className="absolute bottom-8 left-1/2 -translate-x-1/2"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.5 }}
        >
          <div className="flex flex-col items-center gap-2">
            <div className="text-white/80 text-sm">Scroll to explore</div>
            <div className="w-0.5 h-16 bg-gradient-to-b from-white to-transparent" />
          </div>
        </motion.div>
      </section>

      {/* Profile Section */}
      <section className="py-32 bg-muted/50">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-16 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <img
                src={IMAGES.brianOnsite}
                alt="Brian Chappell"
                className="rounded-lg shadow-xl"
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="space-y-8"
            >
              <h2 className="text-4xl font-bold">Brian Chappell</h2>
              <p className="text-xl text-muted-foreground">
                A seasoned digital marketer and entrepreneur with a proven track record
                of building and scaling successful businesses. Brian's expertise spans
                SEO, team management, and business development.
              </p>
              <div className="grid grid-cols-2 gap-8">
                <div className="space-y-2">
                  <div className="text-4xl font-bold text-primary">$1.7M+</div>
                  <div className="text-sm text-muted-foreground">
                    Managed in outsourcing
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="text-4xl font-bold text-primary">50+</div>
                  <div className="text-sm text-muted-foreground">
                    Contractors managed
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="text-4xl font-bold text-primary">5</div>
                  <div className="text-sm text-muted-foreground">
                    Successful exits
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="text-4xl font-bold text-primary">30K+</div>
                  <div className="text-sm text-muted-foreground">
                    Plugin downloads
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="container mx-auto px-4">
        <Timeline />
      </section>

      {/* Testimonials Section */}
      <Testimonials />

      {/* CTA Section */}
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-background to-primary/10" />
        <div className="relative container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="max-w-3xl mx-auto text-center"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              Ready to Start Monitoring Your Competition?
            </h2>
            <p className="text-xl text-muted-foreground mb-8">
              Let our expert team help you stay ahead of your competitors with professional monitoring and analysis.
            </p>
            <Button size="lg" asChild>
              <Link to="/" className="flex items-center gap-2">
                Explore Our Services
                <ArrowRight className="h-5 w-5" />
              </Link>
            </Button>
          </motion.div>
        </div>
      </section>
    </main>
  );
}