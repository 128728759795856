import { Twitch, Youtube } from "lucide-react";
import { Button } from "@/components/ui/button";

// Custom Bluesky icon component
function BlueSkyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M8.5 12.5L10.5 10.5L12.5 12.5L14.5 10.5L16.5 12.5" />
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" />
    </svg>
  );
}

const socials = [
  {
    name: "Twitch",
    href: "https://twitch.tv/rivaltrack",
    icon: Twitch,
  },
  {
    name: "YouTube",
    href: "https://youtube.com/@rivaltrack",
    icon: Youtube,
  },
  {
    name: "Bluesky",
    href: "https://bsky.app/profile/rivaltrack.com",
    icon: BlueSkyIcon,
  },
];

export function FooterSocials() {
  return (
    <div className="flex gap-4">
      {socials.map((social) => (
        <Button
          key={social.name}
          variant="ghost"
          size="icon"
          asChild
        >
          <a
            href={social.href}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-primary transition-colors"
          >
            <social.icon className="h-5 w-5" />
            <span className="sr-only">{social.name}</span>
          </a>
        </Button>
      ))}
    </div>
  );
}