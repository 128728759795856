import { motion } from 'framer-motion';
import { Quote } from 'lucide-react';
import { Card, CardContent } from "@/components/ui/card";

interface Testimonial {
  quote: string;
  author: string;
  title: string;
  company: string;
}

const testimonials: Testimonial[] = [
  {
    quote: "Brian knows his stuff. He knows SEO cold, and he's incredibly passionate about figuring out what's coming next and what it means to all of us.",
    author: "Jim Tobin",
    title: "President",
    company: "Ignite Social Media"
  },
  {
    quote: "I especially appreciate Brian's professionalism, which to me is having direct conversations without being mean, also structuring and documenting ideas so we can actually work on them, developing reasonable goals and timelines.",
    author: "Scott Holdren",
    title: "Co-founder",
    company: "RavenTools.com"
  },
  {
    quote: "Adapt played a critical role in helping us scale our customer acquisition strategy at Buildium",
    author: "Geoff Roberts",
    title: "Previous CMO",
    company: "Buildium.com"
  }
];

export function Testimonials() {
  return (
    <div className="py-24 bg-muted/50">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="max-w-2xl mx-auto text-center mb-16"
        >
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
            What Others Say
          </h2>
          <p className="mt-4 text-lg text-muted-foreground">
            Trusted by industry leaders and professionals
          </p>
        </motion.div>

        <div className="grid gap-8 md:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Card className="h-full">
                <CardContent className="pt-6">
                  <Quote className="h-8 w-8 text-primary/40 mb-4" />
                  <blockquote className="text-lg font-medium leading-8 text-muted-foreground mb-6">
                    "{testimonial.quote}"
                  </blockquote>
                  <div className="border-t pt-4">
                    <div className="font-semibold">{testimonial.author}</div>
                    <div className="text-sm text-muted-foreground">
                      {testimonial.title} • {testimonial.company}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}