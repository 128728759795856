import { Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

const plans = [
  {
    name: "Monthly Reporting",
    price: "399",
    period: "month",
    description: "Perfect for businesses getting started with competitor monitoring",
    features: [
      "Monthly competitor snapshots",
      "Search monitoring",
      "Social media tracking",
      "Traditional media monitoring",
      "Expert consultant analysis",
      "Email or Slack delivery",
    ],
    stripeUrl: "https://buy.stripe.com/9AQ29zdRWcDBgWQdQS",
  },
  {
    name: "Weekly Reporting",
    price: "299",
    period: "week",
    description: "For businesses that need more frequent competitive insights",
    features: [
      "Weekly competitor snapshots",
      "Priority analysis",
      "Search monitoring",
      "Social media tracking",
      "Traditional media monitoring",
      "Expert consultant analysis",
      "Email or Slack delivery",
      "Rapid response insights",
    ],
    stripeUrl: "https://buy.stripe.com/4gw4hH8xCbzx8qkcMN",
  },
];

export function Pricing() {
  return (
    <section id="pricing-section" className="py-24">
      <div className="container">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
            Simple, transparent pricing
          </h2>
          <p className="mt-6 text-lg leading-8 text-muted-foreground">
            Choose the plan that best fits your monitoring needs. All plans include expert analysis and insights.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-5xl grid-cols-1 gap-8 lg:grid-cols-2">
          {plans.map((plan) => (
            <Card key={plan.name} className="flex flex-col relative group">
              <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-primary/20 to-primary/30 opacity-0 blur-xl transition-all group-hover:opacity-100 group-hover:blur-2xl" />
              <div className="relative">
                <CardHeader>
                  <CardTitle className="text-2xl">{plan.name}</CardTitle>
                  <CardDescription>{plan.description}</CardDescription>
                </CardHeader>
                <CardContent className="flex-1">
                  <div className="mt-2 flex items-baseline gap-x-2">
                    <span className="text-5xl font-bold tracking-tight">
                      ${plan.price}
                    </span>
                    <span className="text-muted-foreground">/{plan.period}</span>
                  </div>
                  <ul className="mt-8 space-y-3">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex gap-3">
                        <Check className="h-6 w-6 flex-none text-primary" />
                        <span className="text-muted-foreground">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter>
                  <Button
                    size="lg"
                    className="w-full"
                    asChild
                  >
                    <a href={plan.stripeUrl} target="_blank" rel="noopener noreferrer">
                      Subscribe
                    </a>
                  </Button>
                </CardFooter>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}